// About.js
import React from 'react';
import Layout from "../components/layout";

const Seminars = () => {
    return (
        <Layout>
            <h1>Seminars</h1>
            <h2>Book a Seminar, Course or Demonstration</h2>

            <h3>Sensei Graham Ravey</h3>
            <p>For over 30 years Sensei Graham Ravey has conducted many seminars or gasshukus in the Asia-Pacific region
                and England. He also instructed in the famous Yoyogi Dojo in Tokyo - Japan, Honbu dojo to renowned Goju
                Ryu practitioner Sensei Morio Higaonna. Sensei Ravey is one of the most experienced Okinawan Goju Ryu
                instructors specialising in traditional aspects of the art. Upon invitation, Sensei Ravey is available
                to conduct seminars and gasshukus. To invite Sensei Ravey to conduct a seminar please <a
                    href="/Contact">contact us</a>.</p>

            <h3>TOGKA Instructor in your country or region</h3>
            <p>If you are looking for an instructor in your area please <a href="/Contact">contact us</a> and we may be
                able to recommend an instructor for your upcoming event.</p>
        </Layout>);
}

export default Seminars;
